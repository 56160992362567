import {ICustomer} from 'app/blocks/model/customer.model';
import {ISaleOrder} from 'app/blocks/model/sale-order.model';
import {MposDate} from 'app/blocks/util/mpos-date';

import {BaseEntity, IBaseEntity} from 'app/blocks/model/base-entity.model';
import {IDeliveryPoint} from 'app/blocks/model/delivery-point.model';
import {IDiscount} from 'app/blocks/model/discount.model';
import {IEmployee} from 'app/blocks/model/employee.model';
import {IMajorAccountCode} from 'app/blocks/model/major-account-code.model';
import {IStatementCode} from 'app/blocks/model/statement-code.model';
import {ICreditNoteLine} from 'app/blocks/model/credit-note-line.model';
import {IInvoice} from 'app/blocks/model/invoice.model';
import {IAdHocLine} from 'app/blocks/model/ad-hoc-line';

export interface IApplyCreditNoteDTO {
    invoice: IInvoice;
    amount: number;
}

export class ApplyCreditNoteDTO implements IApplyCreditNoteDTO {
    public invoice: IInvoice;
    public amount: number;
    constructor(invoice?: IInvoice) {
        if (invoice) {
            this.invoice = invoice;
        }
        this.amount = 0;
    }
}

export interface ICreditNote extends IBaseEntity {
    id?: number;
    creditNoteNo?: string;
    reference?: string;
    creditNoteStatus?: any;
    deleted?: boolean;
    subTotal?: number;
    tax?: number;
    shippingCost?: number;
    total?: number;
    notes?: string;
    customer?: ICustomer;
    deliveryPoint?: IDeliveryPoint;
    saleOrder?: ISaleOrder;
    creditNoteLines?: ICreditNoteLine[];
    adHocLines?: IAdHocLine[];
    creationDate?: any;
    updateDate?: any;
    allocatedAmount?: number;
    statementCode?: IStatementCode;
    majorAccountCode?: IMajorAccountCode;
    createdBy?: IEmployee;
    isPDFAvailable?: boolean;
    creditNoteAllocation?: any[];
    approved?: boolean;
    exported?: boolean;
    creditNoteDate?: any;
    invoiceDiscount?: any;
    paymentAllocation?: any[];
}

export class CreditNote extends BaseEntity implements ICreditNote {
    public id?: number;
    public creditNoteNo?: string;
    public reference?: string;
    public creditNoteStatus?: string;
    public deleted?: boolean;
    public subTotal?: number;
    public shippingCost?: number;
    public tax?: number;
    public total?: number;
    public notes?: string;
    public customer?: ICustomer;
    public deliveryPoint?: IDeliveryPoint;
    public creditNoteLines?: ICreditNoteLine[];
    public adHocLines?: IAdHocLine[];
    public creationDate?: any;
    public updateDate?: any;
    public statementCode?: IStatementCode;
    public majorAccountCode?: IMajorAccountCode;
    public createdBy?: IEmployee;
    public isPDFAvailable?: boolean;
    public exported?: boolean;
    public approved?: boolean;
    public creditNoteAllocation?: any[];
    public allocatedAmount?: number;
    public creditNoteDate?: any;
    public invoiceDiscount?: any;
    public paymentAllocation?: any[];

    constructor(creditNote?: any) {
        super();
        if (creditNote) {
            this.id = creditNote.id;
            this.creditNoteNo = creditNote.creditNoteNo;
            this.reference = creditNote.reference;
            this.creditNoteStatus = creditNote.creditNoteStatus;
            this.deleted = creditNote.deleted;
            this.subTotal = creditNote.subTotal;
            this.shippingCost = creditNote.shippingCost || 0;
            this.tax = creditNote.tax;
            this.approved = creditNote.approved;
            this.exported = creditNote.exported;
            this.total = creditNote.total;
            this.notes = creditNote.notes;
            this.customer = creditNote.customer;
            this.allocatedAmount = creditNote.allocatedAmount;

            if (creditNote.customer) {
                if (creditNote.customer.statementCode) {
                    this.statementCode = creditNote.customer.statementCode.description;
                }
                if (creditNote.customer.majorAccountCode) {
                    this.majorAccountCode = creditNote.customer.majorAccountCode.description;
                }
            }
            this.creditNoteAllocation = creditNote.creditNoteAllocation || [];
            this.createdBy = creditNote.createdBy;
            this.deliveryPoint = creditNote.deliveryPoint;
            this.adHocLines = creditNote.adHocLines || [];
            this.creditNoteLines = creditNote.creditNoteLines || [];
            this.creationDate = MposDate.newInstance(creditNote.creationDate);
            this.updateDate = MposDate.newInstance(creditNote.updateDate);
            this.creditNoteDate = MposDate.newInstance(creditNote.creditNoteDate);
            this.isPDFAvailable = creditNote.isPDFAvailable;
            this.invoiceDiscount = creditNote.invoiceDiscount;
            this.paymentAllocation = creditNote.paymentAllocation || [];
        } else {
            this.creditNoteStatus = 'DRAFT';
            this.creationDate = MposDate.currentDate();
            this.creditNoteLines = [];
            this.adHocLines = [];
            this.creditNoteAllocation = [];
            this.allocatedAmount = 0;
            this.paymentAllocation = [];
        }
    }
    get discriminator(): any {
        return this.creditNoteNo;
    }

    clone(): CreditNote {
        return new CreditNote(this);
    }
}
